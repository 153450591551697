import { DenaliRevenueType, lastFiveTransactions, lastFiveYears, openordersTotalByRegion } from "../../types/model";

export function humanReadableValue(value: number | string, toFixed?: number) {
  if (typeof value !== 'number' || isNaN(value)) {
    return "-";
  }

  if (value < 0) {
    // Handle negative values
    value = Math.abs(value); // Convert value to positive
    if (value < 10000) {
      return "-$" + value.toFixed(toFixed || 2);
    } else if (value < 1000000) {
      return "-$" + (value / 1000).toFixed(toFixed || 2) + "K";
    } else if (value < 1000000000) {
      return "-$" + (value / 1000000).toFixed(toFixed || 2) + "M";
    } else {
      return "-$" + (value / 1000000000).toFixed(toFixed || 2) + "B";
    }
  } else {
    if (value < 10000) {
      return "$" + value.toFixed(toFixed || 2);
    } else if (value < 1000000) {
      return "$" + (value / 1000).toFixed(toFixed || 2) + "K";
    } else if (value < 1000000000) {
      return "$" + (value / 1000000).toFixed(toFixed || 2) + "M";
    } else {
      return "$" + (value / 1000000000).toFixed(toFixed || 2) + "B";
    }
  }
}

export function convertToFixedDigit(value: number, fixedDigit: number) {
  if (typeof value !== 'number' || isNaN(value)) {
    return "-";
  }
  return value.toFixed(fixedDigit);
}

export function convertToFixedDigitPercent(value: number, fixedDigit: number) {
  if (typeof value !== 'number' || isNaN(value)) {
    return "0";
  }
  return value.toFixed(fixedDigit);
}

export const getPastYearDateRange = () => {
  const today = new Date();
  const endDate = today.toISOString().split('T')[0];  // Get today's date in YYYY-MM-DD format

  // Calculate the start date by subtracting one year from the current date
  const startDate = new Date(today);
  startDate.setMonth(today.getMonth() - 6);
  const startDateFormatted = startDate.toISOString().split('T')[0];  // Format the start date

  return {
    "startDate": startDateFormatted,
    "endDate": endDate
  };
}

export function getCurrentMonthDates() {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return {
    "startDate": formatDate(startOfMonth),
    "endDate": formatDate(endOfMonth)
  };
}

export function getCurrentQuarterDates() {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3); // Calculate the current quarter
  const startOfQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1);
  const endOfQuarter = new Date(today.getFullYear(), quarter * 3, 0);

  return {
    "startDate": formatDate(startOfQuarter),
    "endDate": formatDate(endOfQuarter)
  };
}

export function getCurrentYearDates() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const endOfYear = new Date(today.getFullYear(), 11, 31);

  return {
    "startDate": formatDate(startOfYear),
    "endDate": formatDate(endOfYear)
  };
}

export const getYearToDateRange = () => {
  const today = new Date();
  const endDate = today.toISOString().split('T')[0];  // Get today's date in YYYY-MM-DD format

  // Calculate the start date of the current year
  const startDate = new Date(today.getFullYear(), 0, 2); // January 1st of the current year
  const startDateFormatted = startDate.toISOString().split('T')[0];  // Format the start date

  return {
    "startDate": startDateFormatted,
    "endDate": endDate
  };
};


export const getPastFiveYearDateRange = () => {
  const today = new Date();
  const endDate = today.toISOString().split('T')[0];  // Get today's date in YYYY-MM-DD format

  // Calculate the start date by subtracting three years from the current date
  const startDate = new Date(today);
  startDate.setFullYear(today.getFullYear() - 5);
  const startDateFormatted = startDate.toISOString().split('T')[0];  // Format the start date

  return {
    "startDate": startDateFormatted,
    "endDate": endDate
  };
}

export function formatToTwoDecimalPlaces(value: any) {
  if (value === null || value === undefined) {
    // Handle null or undefined case as needed
    return "N/A"; // Or any other default value or behavior
  }

  const formattedValue = value.toFixed(2);
  return formattedValue;
}

export function generateStandardTableData(idealData: object, tableResponse: object[]) {
  const dataWithDefaultValues = tableResponse.map(obj => {
    return {
      ...idealData,
      ...obj
    };
  });
  return dataWithDefaultValues;
}

interface ResponseData {
  interval: number;
  [key: string]: number;
}

interface Series {
  name: string;
  data: number[];
}

export function getSeriesFromResponse(responseArray: ResponseData[]) {
  let xAxisCategories: string[] = [];
  let seriesData: { [key: string]: Series } = {};

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.interval?.toString());

    for (const key in elem) {
      if (key !== 'interval') {
        if (!seriesData[key]) {
          seriesData[key] = {
            name: `Sum of ${key}`,
            data: [],
          };
        }
        const seriesDataValue = elem[key]
        seriesData[key].data.push(seriesDataValue);
      }
    }
  });

  let series = Object.values(seriesData);

  return { xAxisCategories, series };
}

export function getSeriesFromLastFiveTransactionsResponse(responseArray: lastFiveTransactions[]) {
  let xAxisCategories: string[] = [];
  let seriesData: Series = {
    name: 'Amount',
    data: []
  };

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.name);
    seriesData.data.push(elem.amount);
  });

  return { xAxisCategories, series: [seriesData] };
}

export function getSeriesFromLastFiveYearsResponse(responseArray: lastFiveYears[]) {
  let xAxisCategories: string[] = [];
  let seriesData: Series = {
    name: 'Amount',
    data: []
  };

  responseArray.forEach((elem) => {
    // Extracting only the year part from the date string
    const year = new Date(elem.year).getFullYear().toString();
    xAxisCategories.push(year);
    seriesData.data.push(elem.amount);
  });

  return { xAxisCategories, series: [seriesData] };
}

export function getStartAndEndDates(inputDate: string) {
  let startDate: Date;
  let endDate: Date;

  // Check if the input date contains a week format
  if (inputDate.includes('-W')) {
    const [year, weekInfo] = inputDate.split('-W');
    let [weekNumberStr, dayOfWeek] = weekInfo.split('-');

    let weekNumber = parseInt(weekNumberStr) - 1;
    if (dayOfWeek === 'Sun') {
      weekNumber -= 1;
    }

    startDate = new Date(parseInt(year), 0, 1);
    startDate.setDate(startDate.getDate() + weekNumber * 7); // Set to the first day of the week

    endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Set to the last day of the week
  }
  // Check if the input date contains a quarter format
  else if (inputDate.includes('-Q')) {
    const [year, quarterStr] = inputDate.split('-Q');
    const quarter = parseInt(quarterStr);
    const month = quarter * 3 - 2;
    startDate = new Date(parseInt(year), month - 1, 1);
    endDate = new Date(parseInt(year), month + 3 - 1, 0);
  }
  // Check if the input date contains a day format
  else if (inputDate.includes('-')) {
    const [yearStr, monthOrDayStr] = inputDate.split('-');
    const year = parseInt(yearStr);
    let month: number;

    if (isNaN(parseInt(monthOrDayStr))) {
      // Attempt to parse monthOrDayStr as a month abbreviation
      const monthAbbreviations = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const monthIndex = monthAbbreviations.indexOf(
        monthOrDayStr.charAt(0).toUpperCase() + monthOrDayStr.slice(1)
      );
      if (monthIndex !== -1) {
        month = monthIndex + 1;
      } else {
        // Return NaN for invalid month
        startDate = new Date(NaN);
        endDate = new Date(NaN);
        return { "startDate": formatDate(startDate), "endDate": formatDate(endDate) };
      }
    } else {
      month = parseInt(monthOrDayStr);
    }

    startDate = new Date(year, month - 1, 1);
    endDate = new Date(year, month, 0);
  }

  // Check if the input date is just a year
  else if (!isNaN(parseInt(inputDate))) {
    const year = parseInt(inputDate);
    startDate = new Date(year, 0, 1);
    endDate = new Date(year, 11, 31);
  }
  // Default to current year if input date is not valid
  else {
    const currentYear = new Date().getFullYear();
    startDate = new Date(currentYear, 0, 1);
    endDate = new Date(currentYear, 11, 31);
  }

  // Format the start and end dates as strings (YYYY-MM-DD format)
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return { "startDate": formattedStartDate, "endDate": formattedEndDate };
}


// Function to format date as "YYYY-MM-DD"
function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getUpdatedRevenueData(staticData: DenaliRevenueType[], apiData: DenaliRevenueType[] | []) {
  // Check if apiData is defined and not empty
  if (!apiData || apiData.length === 0) {
    // If apiData is not available or empty, return staticData with null values
    return staticData.map(staticItem => ({ ...staticItem, amount: null, percent: null }));
  }

  const mergedData = staticData.map(staticItem => {
    const matchingItem = apiData.find(apiItem => apiItem.region_name === staticItem.region_name);
    return matchingItem ? matchingItem : { ...staticItem, amount: null, percent: null };
  });

  return mergedData;
}

export function getSeriesOpenordersTotalByRegion(responseArray: openordersTotalByRegion[]) {

  let xAxisCategories: string[] = [];
  let seriesData: Series = {
    name: 'Region',
    data: []
  };

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.name);
    seriesData.data.push(elem.revenue);
  });

  return { xAxisCategories, series: [seriesData] };

}
export function getSeriesOpenordersTotalByDivision(responseArray: openordersTotalByRegion[]) {
  let xAxisCategories: string[] = [];
  let seriesData: Series = {
    name: 'Division',
    data: []
  };

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.name);
    seriesData.data.push(elem.revenue);
  });

  return { xAxisCategories, series: [seriesData] };
}

export function getSeriesFromResponseForBookingPage(responseArray: ResponseData[]) {
  let xAxisCategories: string[] = [];
  let seriesData: { [key: string]: Series } = {};

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.interval?.toString()?.toUpperCase());

    for (const key in elem) {
      if (key !== 'interval') {
        if (!seriesData[key]) {
          seriesData[key] = {
            name: `${key}`,
            data: [],
          };
        }
        const seriesDataValue = elem[key]
        seriesData[key].data.push(seriesDataValue);
      }
    }
  });

  let series = Object.values(seriesData);

  return { xAxisCategories, series };
}

export function getSeriesOpenordersProjectRevenue(responseArray: openordersTotalByRegion[]) {
  let xAxisCategories: string[] = [];
  let seriesData: Series = {
    name: 'Revenue',
    data: []
  };

  responseArray.forEach((elem) => {
    xAxisCategories.push(elem?.interval);
    seriesData.data.push(elem.revenue);
  });

  return { xAxisCategories, series: [seriesData] };
}