import { ApexOptions } from 'apexcharts';
import { humanReadableValue } from '../../javascript/common/utils/helperFunctions'
import { DenaliRevenueType } from '../types/model';


export const defaultFilterState = {
  region_code: "",
  sales_person_name: "",
  division_code: "",
  revenue_type: "",
  manufacture: ""
}

export const dashboardOverviewData = [
  {
    id: 'revenue',
    title: 'Total Revenue',
    value: '$1.08',
    icon: 'TotalRevenue'
  },
  {
    id: 'customers',
    title: 'Total Customer',
    value: '500',
    icon: 'TotalCustomer'
  },
  {
    id: 'open_orders',
    title: 'Total Open order',
    value: '$0.03',
    icon: 'OpenOrders'
  },
  {
    id: 'bookings',
    title: 'Total Booking',
    value: '$0.12',
    icon: 'Bookings'
  }
];

export const denaliRevenue: DenaliRevenueType[] = [
  {
    region_name: "All Regions",
    amount: 0,
    percent: 0
  },
  {
    region_name: "AsiaPac & India",
    amount: 0,
    percent: 0
  },
  {
    region_name: "North America",
    amount: 0,
    percent: 0
  },
  {
    region_name: "Latin America",
    amount: 0,
    percent: 0
  },
  {
    region_name: "EU & MEA",
    amount: 0,
    percent: 0
  },
  {
    region_name: "Undefined",
    amount: 0,
    percent: 0
  }
]

export const CombinedOptions: ApexOptions = {
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  series: [{
    name: 'Sum Of Revenue',
    type: 'column',
    data: [440, 505, 414, 671, 968]
  },
  {
    name: 'Sum Of Revenue target',
    type: 'column',
    data: [400, 420, 330, 671, 450]
  },
  {
    name: 'Sum Of Revenue Trend',
    type: 'line',
    data: [440, 505, 414, 671, 968],
  }],
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      tools: {
        download: false, // Remove download as SVG option
      },
    },

  },
  markers: {
    size: 3,
  },
  colors: ['#7C51A1', '#18B4E9', '#17497D'],
  stroke: {
    width: [0, 0],
    curve: 'straight',
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [3]
  },
  labels: ['2023', '2022', '2021', '2020', '2019'],
  title: {
    text: 'Last 5 Years',
    style: {
      color: '#18B4E9',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '10px', // Font size of the legend text
    markers: {
      width: 14, // Width of the legend marker
      height: 7, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
};

export const CardWithDoughnutChartOptions: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: ['#18B4E9', '#7C51A1'],
  legend: {
    show: false,
    position: 'left',
  },
  labels: ['Target Revenue', 'Remaining Revenue'],
  plotOptions: {
    pie: {
      donut: {
        size: '60%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    enabled: true,
    y: {
      formatter: function (value) {
        return humanReadableValue(value); 
      }
    }
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 160,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 150,
        },
      },
    },
  ],
};

export const GlobalRevenueBarchartoptions: ApexOptions = {
  colors: ['#7C51A1', '#18B4E9'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'line',
    height: 335,
    stacked: false,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [1.5, 1.5],
    curve: 'straight'
  },
  markers: {
    size: 3,
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '60%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'Target Revenue',
      data: [20, 30, 50, 100, 150, 60, 50, 180, 80, 30, 100, 50],
    },
    {
      name: 'Actual Revenue',
      data: [50, 70, 80, 160, 180, 30, 70, 210, 120, 90, 160, 110],
    }
  ],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '10px', // Font size of the legend text
    markers: {
      width: 14, // Width of the legend marker
      height: 7, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
  fill: {
    opacity: 1,
  },
};

export const AccountTypeBreakoutTableData = Array(15).fill(undefined).map((_, index) => ({
  id: index + 1,
  customer: '3MP Global',
  date: 'Monday, March 03 , 2023',
  region: 'AsiaPac & India',
  division: 'Internal',
  totalRevenue: '11,410.00',
  gm: '$1,382.96',
  invoiceNo: '1176382',
}));

export const dashboardTransactionsOptions: ApexOptions = {
  colors: ['#18B4E9', '#80CAEE'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '25%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 0,
      columnWidth: '25%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetX: 0,
    textAnchor: 'end',
    formatter: function (value: any) {
      return humanReadableValue(value);
    }
  },
  xaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value, 1);
      }
    },
    categories: ['Amazon', 'Google', 'Microsoft', 'Brinks', 'Denali'],
  },
  yaxis:{
    labels: {
      formatter: function (val:any) {
        return val.length > 10 ? val.slice(0, 10) + '...' : val; // Truncate label if longer than 10 characters
      }
    }
  },
  series: [
    {
      name: 'Transections',
      data: [92.4, 102.4, 143.4, 183.4, 203.4],
    },
  ],
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',

    markers: {
      radius: 99,
    },
  },
  title: {
    text: 'Last 5 transactions',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  tooltip: {
    enabled: true,
    y: {
      formatter: function (value) {
        return humanReadableValue(value); 
      }
    },
    x: {
      formatter: function (value:any) {
        return value
      } 
    }
  },
  fill: {
    opacity: 1,
  },
};

export const OpenOrdersLineChartOptions: ApexOptions = {
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  series: [
    {
      name: "Projected Revenue",
      data: [54, 4, 5, 8, 4, 7, 3, 13, 5, 3, 1, 9, 52]
    }
  ],
  chart: {
    height: 350,
    type: 'line',
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: true,
    background: {
      enabled: false,
    },
    style: {
      fontSize: '9px',
      colors: ['#4F4F4F'],
    },
    offsetX: 8,
    offsetY: -5,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  markers: {
    size: 3,
    colors: ['#18B4E9'],
  },
  colors: ['#77B6EA', '#545454'],
  stroke: {
    width: [1, 1],
    curve: 'straight'
  },
  title: {
    text: 'Projected Revenue',
    align: 'left',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    categories: ['Blank', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    floating: true,
    offsetY: -25,
    offsetX: -5
  }
};

export const VerticalStackedBarchartoptions: ApexOptions = {
  colors: ['#7C51A1', '#80CAEE'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: false,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  title: {
    text: 'Revenue Target',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '15px'
    }
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '40%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'Total Revenue',
      data: [20, 30, 50, 100, 150, 60, 50, 180, 80, 30, 100, 50],
    },
    {
      name: 'Total Gross Margin',
      data: [50, 70, 80, 160, 180, 30, 70, 210, 120, 90, 160, 110],
    }
  ],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '14px', // Font size of the legend text
    markers: {
      width: 16, // Width of the legend marker
      height: 8, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
  fill: {
    opacity: 1,
  },
};

export const HardwareCombinedLineChartoptions: ApexOptions = {
  colors: ['#7C51A1', '#80CAEE'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'line',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [1.5, 1.5],
    curve: 'straight'
  },
  title: {
    text: 'Hardware',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '60%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'Sum of Revenue',
      data: [20, 30, 50, 100, 150, 60, 50, 180, 80, 30, 100, 50],
    },
    {
      name: 'Sum of GM',
      data: [50, 70, 80, 160, 180, 30, 70, 210, 120, 90, 160, 110],
    }
  ],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  markers: {
    size: 3,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '14px', // Font size of the legend text
    markers: {
      width: 16, // Width of the legend marker
      height: 8, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
  fill: {
    opacity: 1,
  },
};

export const ServicesCombinedLineChartoptions: ApexOptions = {
  colors: ['#7C51A1', '#80CAEE'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'line',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [1.5, 1.5],
    curve: 'straight'
  },
  markers: {
    size: 3,
  },
  title: {
    text: 'Services',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '60%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'Sum of Revenue',
      data: [20, 30, 50, 100, 150, 60, 50, 180, 80, 30, 100, 50],
    },
    {
      name: 'Sum of GM',
      data: [50, 70, 80, 160, 180, 30, 70, 210, 120, 90, 160, 110],
    }
  ],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '14px', // Font size of the legend text
    markers: {
      width: 16, // Width of the legend marker
      height: 8, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
  fill: {
    opacity: 1,
  },
};

export const OpenOrdersRegion: ApexOptions = {
  colors: ['#18B4E9'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '50%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '50%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
            position: 'top',
        },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  xaxis: {
    categories: ['AsiaPac & India', 'North America', 'EU & MEA', 'Latin America'],
    labels: {
      style: {
        fontSize: '8px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',
    markers: {
      radius: 99,
    },
  },
  title: {
    text: 'Region',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  fill: {
    opacity: 1,
  },
};

export const OpenOrdersDivision: ApexOptions = {
  colors: ['#7C51A1', '#18B4E9'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  title: {
    text: 'Division',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '60%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '50%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
            position: 'top',
        },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  xaxis: {
    categories: ['Q1', 'Q2', 'Q3', 'Q4'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',
    markers: {
      radius: 99,
    },
  },
  fill: {
    opacity: 1,
  },
};

export const BookingsRegion: ApexOptions = {
  colors: ['#18B4E9'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '50%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '50%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'TotalOpenOrders',
      data: [93.4, 52.4, 25.3, 72.4],
    }
  ],
  xaxis: {
    categories: ['AsiaPac & India', 'North America', 'EU & MEA', 'Latin America'],
    labels: {
      style: {
        fontSize: '8px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {

      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',
    markers: {
      radius: 99,
    },
  },
  title: {
    text: 'Region',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  fill: {
    opacity: 1,
  },
};

export const DashboardTopOpenOrdersOptions: ApexOptions = {
  colors: ['#7C51A1', '#80CAEE'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '40%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: any) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'TotalOpenOrders',
      data: [100, 150, 200, 180, 190],
    }
  ],
  xaxis: {
    categories: ['3MP Global', 'Microsoft', 'Denali', '3MP Global', 'Google'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    },
    title: {
      text: 'Customer', // X-axis label
      style: {
        fontSize: '10px',
        color: '#727272',
        fontFamily: 'Inter'
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
    title: {
      text: 'Revenue', // Y-axis label
      style: {
        fontSize: '8px',
        color: '#727272',
        fontFamily: 'Inter'
      },
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',
    markers: {
      radius: 99,
    },
  },
  title: {
    text: 'Top open orders per customer',
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  fill: {
    opacity: 1,
  },
};

export const RegionsFilterResponse = [
  {
    id: 1,
    code: "EMEA",
    name: "EU & MEA",
    visible: true,
  },
  {
    id: 2,
    code: "INDIA",
    name: "AsiaPac & India",
    visible: true,
  },
  {
    id: 3,
    code: "LATAM",
    name: "Latin America",
    visible: true,
  },
  {
    id: 4,
    code: "NA",
    name: "North America",
    visible: true,
  },
  {
    id: 6,
    code: "UNDEFINED",
    name: "Undefined",
    visible: true,
  }
]

export const divisionsFilterResponse = [
  {
    "id": 1,
    "name": "Amazon",
    "code": "AMAZON"
  },
  {
    "id": 2,
    "name": "Enterprise",
    "code": "ENTERPRISE"
  },
  {
    "id": 3,
    "name": "House Account",
    "code": "HOUSE"
  },
  {
    "id": 4,
    "name": "Internal",
    "code": "INTERNAL"
  },
  {
    "id": 5,
    "name": "Undefined",
    "code": "UNDEFINED"
  }
]

export const BookingDivision: ApexOptions = {
  colors: ['#7C51A1', '#18B4E9'],
  noData: {
    text: 'No data to display',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '16px',
      color: '#999'
    }
  },
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'line',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [1.5, 1.5],
    curve: 'straight'
  },
  markers: {
    size: 3,
  },
  title: {
    style: {
      color: '#17497D',
      fontWeight: 600,
      fontSize: '18px'
    }
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '60%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '40%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: '9px',
      colors: ["#000"]
    },
    offsetY: -15,
    formatter: function (value: string | number) {
      return humanReadableValue(value);
    }
  },
  series: [
    {
      name: 'Open',
      data: [20, 30, 50, 100],
    },
    {
      name: 'Closed',
      data: [50, 70, 80, 160],
    }
  ],
  xaxis: {
    categories: ['Q1', 'Q2', 'Q3', 'Q4'],
    labels: {
      style: {
        fontSize: '10px' // Set the desired font size here
      },
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return humanReadableValue(value);
      }
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center', // Horizontal alignment of the legend
    fontSize: '10px', // Font size of the legend text
    markers: {
      width: 14, // Width of the legend marker
      height: 7, // Height of the legend marker
      radius: 0,
    },
    itemMargin: {
      horizontal: 10, // Horizontal margin between legend items
      vertical: 5, // Vertical margin between legend items
    },
  },
  fill: {
    opacity: 1,
  },
};